import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import MainHoursMasterRow from "./MainHoursMasterRow";
import SbuxDialog2 from "../../../../../components/SbuxDialog2";
import { Box, Button, DialogContent, DialogActions } from "@mui/material";
import MainHoursAdd from "./MainHoursAdd";
import MainHoursEdit from "./MainHoursEdit";
import { masterColumns } from "../mainHours/columns";
import { createOrUpdateMainHours } from "../../../../../services/mainHours";
import { isEmptyString } from "../../../../../utils/stringUtil";

const MainHoursMasterTable = ({
  mainHoursData,
  handleRefrehClick,
  isManager,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowToEdit, setRowToEdit] = useState();
  const [dataset, setDataset] = useState({});

  const toggleDialogOpen = () => {
    setOpen((p) => !p);
    setEditRow(false);
  };

  const handleEditRowClick = (row) => {
    setRowToEdit(row);
    setEditRow(true);
    setOpen(true);
  };

  const handleMemoDataset = (data) => {
    const newDataset = {
      ...dataset,
      ...data,
    };
    setDataset(newDataset);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const dialogConfig = {
    title: "Details",
  };

  const handleSave = async () => {
    if (!!dataset) {
      const mainHoursItem = { ...dataset };

      if (isEmptyString(mainHoursItem.hoursName)) {
        return;
      }

      let hoursConfigWithoutIds = [...mainHoursItem.hoursConfig];
      hoursConfigWithoutIds = hoursConfigWithoutIds.reduce((acc, cur) => {
        acc.push({
          start: cur.start,
          end: cur.end,
          description: cur.description,
          day: cur.day,
          isOpenFor24Hours: cur.isOpenFor24Hours,
        });
        return acc;
      }, []);
      const readOnlyValue = isManager
        ? mainHoursItem.readOnly
        : mainHoursItem.readOnly || false;

      const data = {
        hoursCode: mainHoursItem.hoursCode,
        hoursName: mainHoursItem.hoursName,
        description: mainHoursItem.description,
        hoursConfig: [...hoursConfigWithoutIds],
        showForManagers: isManager || mainHoursItem.showForManagers || false,
        readOnly: readOnlyValue,
      };
      toggleDialogOpen();

      await dispatch(createOrUpdateMainHours(data));
      handleRefrehClick(); // Refresh data after save
    }
  };

  const isFormValid = () => {
    if (!!dataset) {
      const { hoursName } = dataset;
      if (isManager && dataset.readOnly) {
        return true;
      }
      return isEmptyString(hoursName);
    }
    return false;
  };

  return (
    <>
      <SbuxEnhancedTable
        rows={mainHoursData} // Pass filtered or unfiltered data
        columns={masterColumns}
        defaultSortOrder={"asc"}
        RowSource={MainHoursMasterRow}
        showFooter
        showSearchBar
        showAddNewButton
        isdisableAddNewButton={isManager}
        addNewButtonTitle={`Add new hours`}
        handleAddNewButtonClick={toggleDialogOpen}
        isTranslationRequired={false}
        handleRefreshClick={handleRefrehClick}
        handleEditRowClick={handleEditRowClick}
        isManager={isManager}
        isLoading={isLoading}
      />

      {open && (
        <SbuxDialog2
          open={open}
          config={dialogConfig}
          handleOnClose={toggleDialogOpen}
        >
          <DialogContent dividers>
            {!editRow ? (
              <MainHoursAdd
                toggleDialogOpen={toggleDialogOpen}
                memoDataset={handleMemoDataset}
              />
            ) : (
              <MainHoursEdit
                row={rowToEdit}
                toggleDialogOpen={toggleDialogOpen}
                memoDataset={handleMemoDataset}
                isManager={isManager}
              />
            )}
          </DialogContent>

          <DialogActions>
            <Box className="actionContainer">
              <Button
                variant="contained"
                size="medium"
                disabled={isFormValid()}
                onClick={handleSave}
              >
                Save
              </Button>

              <Button
                variant="outlined"
                size="medium"
                onClick={toggleDialogOpen}
              >
                Close
              </Button>
            </Box>
          </DialogActions>
        </SbuxDialog2>
      )}
    </>
  );
};

export default MainHoursMasterTable;
