import {
  Box,
  Paper,
  Button,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";

import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  dataNormalizer,
  normalizeContentSpec,
  getDataColumns,
  getRowAttributes,
  normalizeTableList,
  getDefaultSortModel,
} from "./utils";

import {
  getTableListData,
  getTableMetaData,
  createOrUpdateListData,
} from "../../../../../services/dataMgt";

import SbuxDialog2 from "../../../../../components/SbuxDialog2";
import SbuxLoader from "../../../../../components/SbuxLoader";
import SbuxSelect from "../../../../../components/SbuxSelect";

import {
  SbuxGenericGrid,
  SbuxGridToolbar,
} from "../../../../../components/SbuxDataGrid/SbuxGenericGrid";
import { StyledDeleteButton } from "../../styled";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import DataManagementDialog from "../../common/DataManagementDialog";

import { clearState } from "../../../../../slices/dataMgtSlice";
import { getInstanceIdSelector } from "../../../../../selectors/userInstanceSelector";
import {
  getMetaConfigDataSelector,
  getTableListDataSelector,
  getTableListLoadingSelector,
} from "../../../../../selectors/dataMgtSelector";
import { getTimezoneSelector } from "../../../../../selectors/settingsSelector";

import styles from "./styles";
import useCss from "../../../../../hooks/useCss";

const DataManagementTabs = () => {
  const classes = useCss(styles);
  const dispatch = useDispatch();

  /* Selectors */
  const instanceId = useSelector(getInstanceIdSelector);
  const metaConfigData = useSelector(getMetaConfigDataSelector);
  const tableListData = useSelector(getTableListDataSelector);
  const tableListLoading = useSelector(getTableListLoadingSelector);
  const timezone = useSelector(getTimezoneSelector);

  /* Individual state declarations */
  const [openDialogue, setOpenDialogue] = useState(false);
  const [openDeleteDialogue, setOpenDeleteDialogue] = useState(false);
  const [row, setRow] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [tableName, setTableName] = useState(null);
  const [tableFriendlyName, setTableFriendlyName] = useState(null);
  const [addNew, setAddNew] = useState(false);
  const [defaultValue, setdefaultValue] = useState("");
  const [isDisableAddNewButton, setIsDisableAddNewButton] = useState(true);
  const [crudOperations, setCrudOperations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [contentSpec, setContentSpec] = useState({});
  const selectedTable = useRef(null);
  const [initialLoadInProgress, setInitialLoadInProgress] = useState(true);
  const [sortModel, setSortModel] = useState([]);

  /**
   * Dialog configuration
   */
  const dialogConfig = {
    title: "Delete Item",
    hasBackdrop: false,
    asModal: true,
  };

  /**
   * Retrieves selected table's data
   * @param  {string}  tableName               selected table's name
   */
  const fetchTableData = async (tableName) => {
    if (tableName) {
      await dispatch(getTableListData(tableName));
    }
  };

  /**
   * Retrieves metadata config data
   */
  const fetchMetadataConfig = async () => {
    await dispatch(getTableMetaData());
  };

  useEffect(() => {
    fetchMetadataConfig();

    return () => {
      dispatch(clearState());
    };
  }, [instanceId]);

  /**
   * Loading action
   */
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(tableListLoading);
    }, 100);

    initialLoadInProgress &&
      setTimeout(() => {
        setInitialLoadInProgress(false);
      }, 400);
  }, [tableListLoading]);

  /**
   * Data action
   */
  useEffect(() => {
    if (metaConfigData?.tables?.length) {
      const tableList = normalizeTableList(metaConfigData?.tables);

      setFilterList(tableList);

      const defaultTable =
        tableName || selectedTable.current || tableList[0]?.value;
      if (defaultTable) {
        handleTableSelection(defaultTable);
      }
    }
  }, [metaConfigData /*selectedTable.current, tableName*/]);

  /**
   * Action button definition spec
   * @return {Object} button definition spec
   */
  const actionButtonSpec = () => {
    return {
      headerName: "Action",
      field: "action",
      id: "action",
      type: "actions",
      sortable: false,
      renderCell: (params) => (
        <StyledDeleteButton
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            handleDeleteRowClick(params.row);
          }}
          disableFocusRipple
          disableRipple
        />
      ),
      display: "flex",
      align: "center",
    };
  };

  /**
   * Handle table selection event
   * @param  {string} selectedTableName               table name
   */
  const handleTableSelection = (selectedTableName) => {
    if (!selectedTableName) return;

    const selectedItem = metaConfigData?.tables?.find(
      (item) => item.tableName === selectedTableName
    );

    if (selectedItem) {
      fetchTableData(selectedItem?.tableName);

      const newContentSpec = normalizeContentSpec(
        selectedItem?.config?.attributes ?? []
      );

      const tempCrudOperations = selectedItem?.crudOperations;
      if (tempCrudOperations && tempCrudOperations.includes("DELETE")) {
        const buttonSpec = actionButtonSpec();
        newContentSpec.columns.push(buttonSpec);
      }

      setContentSpec(newContentSpec);

      setCrudOperations(tempCrudOperations);
      if (tempCrudOperations.includes("CREATE")) {
        setIsDisableAddNewButton(false);
      } else {
        setIsDisableAddNewButton(true);
      }

      setTableFriendlyName(selectedItem?.friendlyName);
      setdefaultValue(selectedTableName);

      // preventing re-renders
      if (selectedTableName !== tableName) {
        selectedTable.current = selectedTableName;
        setTableName(selectedTableName);
      }
    }
  };

  const handleModal = (isNew = false) => {
    setAddNew(isNew);
    setOpenDialogue((prevState) => !prevState);
  };

  /**
   * Handle onRow click event. Open a modal passing row data to edit
   * @param  {Object} params               row params
   */
  const handleOnRowClick = (params) => {
    setRow({ ...params.row });
    handleModal();
  };

  /**
   * Prevent propagation of event for cells under 'action' column
   * @param  {Object} param               row params
   * @param  {Object} event               DataGrid event
   */
  const handleOnCellClick = (param, event) => {
    param.field === "action" && event.stopPropagation();
  };

  const handleSave = async (listDataPayload) => {
    // Needed for any key change on updates
    if (listDataPayload?.isEdit) {
      const originalAttributes = getRowAttributes(row);
      listDataPayload.payload.originalAttributes = originalAttributes;
    }

    const result = await dispatch(createOrUpdateListData(listDataPayload));
    if (result?.meta?.requestStatus === "fulfilled") {
      handleModal();
      setRow(null);
      fetchTableData(listDataPayload.tableName);
    }
  };

  /**
   * Handles the delete confirmation action of a selected row
   */
  const handleDeleteRow = async () => {
    const attributes = getRowAttributes(row);

    const listDataPayload = {
      payload: {
        attributes: attributes,
      },
      tableName: tableName,
      isDelete: true,
    };

    const result = await dispatch(createOrUpdateListData(listDataPayload));
    if (result?.meta?.requestStatus === "fulfilled") {
      setOpenDeleteDialogue(false);
      fetchTableData(tableName);
    }
  };

  useEffect(() => {
    const defaultSortModel = getDefaultSortModel(contentSpec.columns);
    setSortModel(defaultSortModel); // Apply the default sorting model
  }, [contentSpec]);

  /**
   * Handles onClick event for the refresh button
   */
  const handleOnRefreshButtonClick = async () => {
    // if (!tableName) return;

    setIsLoading(true);
    // fetch metadata only. table data will be fetch once table selection is
    // invoke later on
    const defaultSortModel = getDefaultSortModel(contentSpec.columns);
    setSortModel(defaultSortModel);
    await fetchMetadataConfig();
  };

  /**
   * Handles onDeleteRow click event
   * @param  {Object} row               row to delete
   */
  const handleDeleteRowClick = (row) => {
    setRow(row);
    setOpenDeleteDialogue(true);
  };

  /**
   * TableSelect component to describe the rendering of the Datagrid toolbar
   */
  const TableSelect = () => {
    if (!filterList) {
      return null;
    }

    return (
      <SbuxGridToolbar>
        <FormControl>
          <InputLabel>
            <SbuxTranslate>{`Make a selection`}</SbuxTranslate>
          </InputLabel>
          <SbuxSelect
            menuItems={filterList}
            handleSelectChange={handleTableSelection}
            value={defaultValue}
          />
        </FormControl>

        <Button
          variant="contained"
          size="medium"
          onClick={() => handleOnRefreshButtonClick()}
        >
          {<SbuxTranslate>{"Refresh"}</SbuxTranslate>}
        </Button>

        <Button
          variant="contained"
          size="medium"
          onClick={() => handleModal(true)}
          disabled={isDisableAddNewButton}
        >
          {<SbuxTranslate>{"Add New"}</SbuxTranslate>}
        </Button>
      </SbuxGridToolbar>
    );
  };

  return (
    <>
      <Paper>
        {initialLoadInProgress ? (
          <SbuxLoader />
        ) : tableListData?.length > 0 && contentSpec.columns?.length ? (
          <>
            <div>
              <SbuxGenericGrid
                rows={dataNormalizer(
                  tableListData,
                  contentSpec.columns,
                  timezone
                )}
                columns={contentSpec.columns}
                loading={isLoading}
                slots={{
                  toolbar: TableSelect,
                }}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                slotClasses={{
                  datagrid: {
                    container: classes.datagridContainer,
                  },
                }}
                onRowClick={handleOnRowClick}
                onCellClick={handleOnCellClick}
                getEstimatedRowHeight={contentSpec.getEstimatedRowHeight}
                getRowHeight={contentSpec.getRowHeight}
                sx={{
                  ...contentSpec.sx,
                }}
                clickableRow
              />
            </div>
          </>
        ) : null}
        {openDialogue && (
          <DataManagementDialog
            title={
              addNew ? (
                <SbuxTranslate>{`Create new ${tableFriendlyName}`}</SbuxTranslate>
              ) : (
                <SbuxTranslate>{`Edit ${tableFriendlyName}`}</SbuxTranslate>
              )
            }
            open={openDialogue}
            toggleDialogOpen={() => setOpenDialogue(false)}
            row={row}
            rows={tableListData}
            addNew={addNew}
            tableName={tableName}
            onSave={handleSave}
            columns={getDataColumns(contentSpec.columns)}
            crudOperations={crudOperations}
          />
        )}

        {openDeleteDialogue && (
          <SbuxDialog2 open={openDeleteDialogue} config={dialogConfig}>
            <DialogContent dividers>
              <Typography variant="h5">
                <SbuxTranslate>
                  {"Are you sure you want to delete it?"}
                </SbuxTranslate>
              </Typography>
            </DialogContent>

            <DialogActions>
              <Box className="actionContainer">
                <Button
                  variant="contained"
                  size="medium"
                  onClick={handleDeleteRow}
                >
                  <SbuxTranslate>{`Delete`}</SbuxTranslate>
                </Button>
                <Button
                  variant="outlined"
                  size="medium"
                  onClick={() => setOpenDeleteDialogue(false)}
                >
                  <SbuxTranslate>{`Cancel`}</SbuxTranslate>
                </Button>
              </Box>
            </DialogActions>
          </SbuxDialog2>
        )}
      </Paper>
    </>
  );
};

export default DataManagementTabs;
