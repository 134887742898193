import { gridClasses } from "@mui/x-data-grid";
import { capitalize } from "../../../../../utils/stringUtil";
import {
  formatLastUpdated,
  formatDateAtTimezoneOrDefault,
} from "../../../../../utils/tableUtil";

const ColumnSizeCondition = {
  inputMaxLength: 100,
  maxColumnSize: 300,
};

export const commonRenderCell = (params, timezone) => {
  const { field, row, value, colDef } = params;
  if (field === "lastUpdated") {
    return formatLastUpdated(row, timezone);
  }

  if (field === "dateTime") {
    return formatDateAtTimezoneOrDefault(value, timezone, "No date");
  }

  if (
    field !== "action" &&
    colDef.type === "boolean" &&
    typeof value === "boolean"
  ) {
    return `${value}`;
  }

  return value;
};

/**
 * Render cell function to present boolean values for cells where colDef.type === boolean
 * @param  {any} value               a value
 * @return {string}       presentation value
 */
export const booleanRenderCell = ({ value }) => {
  return value ? capitalize(`${value}`, true) : "";
};

/**
 * Normalize a value
 * @param  {any} value               any value
 * @return {any}       normalized value
 */
const normalizeValue = (value) => {
  const booleanValues = new Set(["true", "false"]);

  if (typeof value === "boolean") return `${value}`;

  const lowerCaseValue = value?.trim().toLowerCase();
  if (lowerCaseValue && booleanValues.has(lowerCaseValue)) {
    return `${lowerCaseValue === "true"}`;
  }

  return value;
};

/**
 * Data normalizer to prepare field.values with transform values. This practice allows filtering
 * to be effective in calculated values
 * @param  {Array} dataset                dataset to map
 * @param  {Array} colSpecs               list of columns
 * @param  {string} timezone               time zone code
 * @return {Array}          normalized array
 */
export const dataNormalizer = (dataset, colSpecs, timezone) => {
  const dataTypeMap = new Map(
    colSpecs
      .filter((col) => col.type === "boolean")
      .map((col) => [col.id, col.type])
  );

  return dataset.map((record) => {
    const normalRecord = { ...record };
    if (normalRecord.lastUpdated) {
      normalRecord.lastUpdated = formatLastUpdated(normalRecord, timezone);
    }

    if (normalRecord.dateTime) {
      normalRecord.dateTime = formatDateAtTimezoneOrDefault(
        normalRecord.dateTime,
        timezone,
        "No date found"
      );
    }

    dataTypeMap.forEach((value, key) => {
      const recordValue = normalRecord[key];
      normalRecord[key] = normalizeValue(recordValue);
    });

    return normalRecord;
  });
};

/**
 * Content normalization function
 * @param  {Object} attributes               content attributes
 * @return {Object}            content spec object
 */
export const normalizeContentSpec = (attributes) => {
  const columns = attributes
    ?.filter((attribute) => !attribute.hideOnUi)
    ?.map((item) => {
      return {
        id: item.name,
        field: item.name,
        headerName: item.friendlyName,
        label: item.friendlyName,
        // disabled: item.lockedForEdit,
        lockedForEdit: item.lockedForEdit,
        type: item.type,
        uiPosition: item.uiPosition,
        required: item.required,
        inputMaxLength: item.maxLength,
        partitionKey: item.partitionKey ? item.partitionKey : false,
        sortable: true,

        /* Column size strategy. Feel free to adjust */
        flex:
          item?.maxLength > ColumnSizeCondition.inputMaxLength ? 1 : undefined,
        maxWidth:
          item?.maxLength > ColumnSizeCondition.inputMaxLength
            ? ColumnSizeCondition.maxColumnSize
            : undefined,
        /* */
        renderCell: item.type === "boolean" ? booleanRenderCell : undefined,
      };
    })
    .sort((a, b) => a.uiPosition - b.uiPosition);

  const wideContent = columns.some((column) => column.maxWidth);
  const getRowHeight = wideContent ? () => "auto" : undefined;
  const getEstimatedRowHeight = wideContent ? () => 32 : undefined;
  const sx = wideContent
    ? {
        [`& .${gridClasses.cell}`]: {
          py: 1,
        },
      }
    : {};

  return {
    columns,
    getRowHeight,
    getEstimatedRowHeight,
    sx,
  };
};

/**
 * Normalization of table list
 * @param  {Array}  [tableList=[]]               list of tables
 * @return {Array}                normalize version of the table list
 */
export const normalizeTableList = (tableList = []) => {
  return tableList
    .map((item) => ({
      name: item.friendlyName,
      value: item.tableName,
      uiPosition: item.uiPosition,
    }))
    .sort((a, b) => a.uiPosition - b.uiPosition);
};

/**
 * Returns a list of columns that present data
 * @param  {Array} columns               list of column specs
 * @return {Array}         list of columns
 */
export const getDataColumns = (columns) => {
  return columns.filter((column) => column?.id !== "action");
};

/**
 * Return a list of key-value pairs of the given row object
 * @param  {Object} row               a row
 * @return {Array}     a list of properties of the row
 */
export const getRowAttributes = (row) => {
  const attributes = [];
  for (const key in row) {
    key !== "__rowId" && attributes.push({ name: key, value: row[key] });
  }
  return attributes;
};

/**
 * Computes the default sorting model for a data grid.
 * @param {Array} columns - Array of column definitions.
 * @returns {Array} - The default sort model (e.g., [{ field: "columnId", sort: "asc" }]) or an empty array.
 */
export const getDefaultSortModel = (columns) => {
  const defaultSortingField = columns?.find((col) => col.uiPosition === 1)?.id;

  if (defaultSortingField) {
    return [{ field: defaultSortingField, sort: "asc" }];
  }

  return [];
};
