import { createAsyncThunk } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getMetaData = async (instanceId, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/metadataConfig/${instanceId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `Data Management`,
      message: `Something went wrong in getmetadataConfigAPI => ${error}`,
    });
    logMessage(
      `dataMgt`,
      `Something went wrong in getmetadataConfigAPI API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getmetadataConfigAPI api endpoint");
  }
};

export const getMetaDataMgt = async (tableName, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/metadataManagement/${tableName}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `Data Management`,
      message: `Something went wrong in getmetadataManagement API => ${error}`,
    });
    logMessage(
      `dataMgt`,
      `Something went wrong in getmetadataManagement API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getmetadataManagement api endpoint");
  }
};

export const createOrUpdateListData = createAsyncThunk(
  "dataMgt/createOrUpdateListData",
  async (listDataPayload, { rejectWithValue }) => {
    const method = listDataPayload?.isDelete
      ? "DELETE"
      : listDataPayload?.isEdit
      ? "PUT"
      : "POST";
    try {
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/metadataManagement/${listDataPayload.tableName}`,
        method: method,
        body: { ...listDataPayload.payload },
      });
      return listDataPayload?.isDelete ? "delete" : "createOrUpdate";
    } catch (error) {
      clientLog({
        component: `Data Management`,
        message: `Something went wrong in createOrUpdatemetadataManagementListData API => ${error}`,
      });
      logMessage(
        `Data Management`,
        `Something went wrong in createOrUpdatemetadataManagementListData API => ${error}`,
        `error`
      );
      return rejectWithValue(
        "Error in createOrUpdatemetadataManagementListData api endpoint"
      );
    }
  }
);

export const getTableListData = createAsyncThunk(
  "dataMgt/getTableListData",
  async (tableName, { rejectWithValue }) => {
    let tableListData = [];
    if (tableName) {
      tableListData = await getMetaDataMgt(tableName, rejectWithValue);
    }
    return { tableListData };
  }
);

export const getTableMetaData = createAsyncThunk(
  "dataMgt/getTableMetaData",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    const headerData = await getMetaData(instanceId, rejectWithValue);
    return { headerData };
  }
);
